import React from 'react'
import Link from 'gatsby-link'

import HeaderStyle from './header.module.scss'

// import FooterStyles from './footer.module.scss'

const Header = (props) => {
  return (
    <header
      style={{
        padding: '100px 0',
        background: 'no-repeat center',
        backgroundSize: 'cover',
        backgroundImage: `url(/background.jpg)`
      }}
    >
      <div className={HeaderStyle.blogInfo}>
        <h2>
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to="/">{props.title}</Link>
        </h2>
        <h3>
          Über Kommunikation, Social Skills und Training
    </h3>
      </div>
    </header>
  )
}

export default Header