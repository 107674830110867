import React from 'react'
import Link from 'gatsby-link'

import FooterStyles from './footer.module.scss'

export default function Footer() {
 return (
  <footer className={FooterStyles.footer}>
      <ul>
          <li>
          <Link to="impressum">Impressum</Link>
          </li>
          <li>
          © {new Date().getFullYear()}, Built by Jacques Sauvaget
          </li>
      </ul>    
  </footer>
 )
}
